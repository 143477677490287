import { useCallback, FC } from 'react';
import Image from 'next/legacy/image';
import Grid from 'antd/lib/grid';
import styles from '@styles/Homepage/app_showcase.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from 'providers';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ComponentTbaLandingSectionsShowcase } from 'services/graphql/cms';
import Link from 'next/link';

interface Props {
  showCaseData: ComponentTbaLandingSectionsShowcase;
}

const AppShowcase: FC<Props> = ({ showCaseData }) => {
  const { isAuthenticated } = useAuth0();
  const { theboatAppUrl } = useConfig();

  const { sm, xs } = Grid.useBreakpoint();

  return (
    <section className={styles.container}>
      <div className={styles.container__image}>
        <Image
          src={showCaseData?.image?.data?.attributes?.url}
          alt={showCaseData?.image?.data?.attributes?.alternativeText}
          width={538}
          height={610}
        />
      </div>

      <div className={styles.container__content}>
        <h2 className={styles.content__title}>{showCaseData?.title}</h2>
        <div className={styles.content__text} dangerouslySetInnerHTML={{ __html: showCaseData?.description }} />

        {sm && (
          <>
            {isAuthenticated ? (
              <Button href={theboatAppUrl} className="ant-btn-special" size="large">
                Go to Dashboard <ArrowRightOutlined />
              </Button>
            ) : (
              <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-lg ant-btn-special">
                Get started <ArrowRightOutlined />
              </Link>
            )}
          </>
        )}
      </div>

      {xs && (
        <div className={styles.container__bootom__button}>
          {isAuthenticated ? (
            <Button href={theboatAppUrl} block className="ant-btn-special">
              Go to Dashboard <ArrowRightOutlined />
            </Button>
          ) : (
            <Link href={'/register'} className="ant-btn ant-btn-default ant-btn-block ant-btn-special">
              Get started <ArrowRightOutlined />
            </Link>
          )}
        </div>
      )}
    </section>
  );
};

export default AppShowcase;
