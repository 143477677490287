import { FC, useState } from 'react';
import styles from './PricingFeatures.module.scss';
import { PricingFeaturesProps } from './PricingFeatures.props';
import Carousel from 'antd/lib/carousel';
import { Enum_Feature_Group, Feature, FeatureEntity } from 'services/graphql/cms';
import FeaturesItem from './FeaturesItem';
import FeatureTabs from './FeatureTabs';
import carouselOptions from './carousel.json';

const PricingFeatures: FC<PricingFeaturesProps> = ({ features, isHome, carouselData }) => {
  // TODO if the general tab is going to be visible
  const categories = isHome
    ? Object.values(Enum_Feature_Group).slice(1, 3)
    : Object.values(Enum_Feature_Group).reverse().slice(0, 2);

  const cards = features.data
    .sort((a: FeatureEntity, b: FeatureEntity) => a!.attributes!.order - b!.attributes!.order)
    .filter((item: FeatureEntity) => item?.attributes?.showOnCarousel);

  const [carouselCards, setCarouselCards] = useState(cards);

  const callback = (key: string): void => {
    if (key === 'all') {
      setCarouselCards(cards);

      return;
    }

    const filteredCards = cards.filter((item) => item?.attributes?.group?.toLocaleLowerCase() === key);

    setCarouselCards(filteredCards);
  };

  return (
    <section className={`${styles.pricingFeatures} ${isHome ? styles.homeBbColor : ''}`}>
      <div className={styles.pricingFeaturesHeader}>
        <h1>{carouselData?.title}</h1>
        {carouselData?.description ? <div dangerouslySetInnerHTML={{ __html: carouselData?.description }} /> : null}
      </div>
      <div className="container">
        <FeatureTabs callback={callback} categories={categories} />

        <Carousel {...carouselOptions}>
          {carouselCards.map((feature) => (
            <FeaturesItem feature={feature.attributes as Feature} key={feature.id} isHome={isHome} />
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export { PricingFeatures };
