import React, { Fragment } from 'react';

import Tabs from 'antd/lib/tabs';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any;
  categories: any;
};

const FeatureTabs: React.FC<Props> = ({ callback, categories }) => {
  const { TabPane } = Tabs;

  const cards = [1, 2];

  const createTabPane = (items: JSX.Element[]) => {
    const allCategories = {};

    items.forEach((item) => {
      categories.forEach((category: any) => {
        const lowerCaseCategory = category.toLowerCase();

        if (!allCategories[lowerCaseCategory]) {
          allCategories[lowerCaseCategory] = category;
        }
      });
    });

    return Object.keys(allCategories).map((category) => (
      <Fragment key={category}>
        <TabPane tab={allCategories[category]} key={category} />
      </Fragment>
    ));
  };

  return (
    <Tabs defaultActiveKey="all" onChange={callback} centered>
      <TabPane tab="All" key="all" />
      {createTabPane(cards as any)}
    </Tabs>
  );
};

export default FeatureTabs;
